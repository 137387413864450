import classNames from 'classnames';
import Image from 'next/future/image';
import styles from './slide.module.css';

const Slide = (item) => {
  return (
    item?.original && (
      <Image
        className={classNames('image-gallery-image', styles.slide)}
        src={item?.original}
        alt=''
        loading='lazy'
        width={100}
        height={100}
      />
    )
  );
};

export default Slide;
