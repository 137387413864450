import React, { Fragment, useState } from 'react';
import { useTranslation } from 'next-i18next';
import { useAuthContext } from 'components/shared/Auth';

import { CommentOutlined, HeartOutlined, MessageOutlined, PhoneOutlined, ShoppingOutlined } from '@ant-design/icons';
import Link from 'components/shared/elements/Link';
import css from '../../ant/css/cards.module.css';
import { Tooltip } from 'antd';
import axios from 'axios';
import { API_ROOT } from 'modules/request';

export default function CardFooter({ item }) {
  const { t } = useTranslation('common');
  const { accessToken } = useAuthContext();
  const [likes, setLikes] = useState(item?.likesNum ?? 0);

  // console.log(item);

  const contacts = (
    <div>
      <div>{item.authorName}</div>
      {item.contacts?.phone ?? item.offerUrl}
    </div>
  );

  const addLike = (id) => {
    setLikes(likes + 1);
    axios // Why axios? We have getRequest
      .post(
        `${API_ROOT}/listings/${id}/likes`,
        {},
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        },
      )
      .then((response) => {
        getItemData(id);
      })
      .catch((error) => {});
  };

  const getItemData = (id) => {
    axios // Why axios? We have getRequest
      .get(`${API_ROOT}/listings/${id}`, {})
      .then((response) => {
        // console.log(response);
        setLikes(response?.data?.likesNum ?? 0);
      })
      .catch((error) => {});
  };

  return (
    <>
      <div
        className={css.fButton}
        // style={{
        //   minWidth: '40px',
        //   flex: '1',
        // }}
      >
        <Link href={`/listings/${item.slug}`}>
          <MessageOutlined /> 0 / 0
        </Link>
      </div>
      <div
        className={css.fButton}
        onClick={() => {
          addLike(item.id);
        }}
      >
        <HeartOutlined /> {likes}
      </div>
      <div className={css.fButton}>
        <Link href={`/chat/users/${item.authorId}`}>
          <CommentOutlined /> 0
        </Link>
      </div>
      <div className={css.fButton}>
        <Tooltip placement='topLeft' trigger='click' title={contacts}>
          <PhoneOutlined />
        </Tooltip>
      </div>
      <div className={css.fButton}>
        {item.offerUrl && (
          <Link target='_blank' href={item.offerUrl}>
            <ShoppingOutlined />
          </Link>
        )}
        {!item.offerUrl && <ShoppingOutlined />}
      </div>
    </>
  );
}
