import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'next-i18next';

import css from '../../ant/css/cards.module.css';
import ImageGallery from 'react-image-gallery';
import { useRouter } from 'next/router';
import { getImageVersionUrl } from 'lib/images';
import { LISTING_GALLERY_IMAGE_NUMBER } from 'constants';
import { CARD_GALLERY_IMAGE_NUMBER } from 'constants';
import { VIP_CARD_GALLERY_IMAGE_NUMBER } from 'constants';
import Link from 'components/shared/elements/Link';
import Slide from 'components/ant/ui/Cards/Slide';

export default function CardGallery({ item, isVip = false, orientation = 1 }) {
  const router = useRouter();
  const isListingPage = ['/[location]/listings/[page]', '/private/listings/edit/[slug]'].includes(router.route);
  const { t } = useTranslation('common');
  const imgLimit = isListingPage
    ? LISTING_GALLERY_IMAGE_NUMBER
    : isVip
      ? VIP_CARD_GALLERY_IMAGE_NUMBER
      : CARD_GALLERY_IMAGE_NUMBER;

  // console.log(item);

  const [targetReached, setTargetReached] = useState(false);

  const updateTarget = useCallback(
    (e) => {
      if (e.matches) {
        setTargetReached(true);
      } else {
        setTargetReached(false);
      }
    },
    [setTargetReached],
  );

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const media = window.matchMedia(`(max-width: ${600}px)`);
      media.addEventListener('change', updateTarget);

      if (media.matches) {
        setTargetReached(true);
      }

      return () => media.removeEventListener('change', updateTarget);
    }
  }, []);

  function _renderVideo(item) {
    let height = item.height < item.width ? '180px' : item.height > 700 ? '700px' : item.height;

    return (
      <div style={{ height: '100%' }}>
        <iframe
          className={css.videoIframe}
          style={{ height: targetReached ? `${height}` : 'auto' }}
          src={item.embedUrl}
          frameBorder='0'
          allowFullScreen
        ></iframe>
      </div>
    );
  }

  const covers = (item?.covers || []).map((value) => value.id);

  const images =
    (Array.isArray(covers) &&
      covers.map((value) => ({
        original: getImageVersionUrl({
          id: value,
          version: orientation == 1 ? 'tiny' : orientation < 1 ? 'inst_hor' : 'inst',
        }),
        thumbnail: getImageVersionUrl({ id: value, version: 'tiny' }),
      }))) ||
    [];

  const videos =
    (Array.isArray(item?.videos) &&
      item?.videos.map(({ id, width, height }) => ({
        embedUrl: `https://customer-ja6fhk6t3y92qtj8.cloudflarestream.com/${id}/iframe`,
        width: width,
        height: height,
        renderItem: _renderVideo.bind(this),
      }))) ||
    [];

  return (
    <Link href={`/listings/${item.slug}`} title={item.title}>
      {images.length === 1 && (
        <div>
          <img
            src={images[0].original}
            alt=''
            style={{ width: '100%', objectFit: 'cover', objectPosition: 'center' }}
          />
        </div>
      )}
      {((images.length === 0 && videos) || images.length > 1) && (
        <ImageGallery
          items={[...images, ...videos]}
          showNav={false}
          showThumbnails={false}
          showFullscreenButton={false}
          useBrowserFullscreen={false}
          showPlayButton={false}
          showBullets
          originalClass={css.gallery}
          loading='lazy'
          thumbnailLoading='lazy'
          renderItem={Slide}
        />
      )}
    </Link>
  );
}
