import React, { useEffect, useState } from 'react';
import { EnvironmentOutlined, UserOutlined } from '@ant-design/icons';
import css from '../../ant/css/cards.module.css';
import styles from 'components/ant/css/stylesIndex.module.css';
import { Menu, Dropdown, Tooltip } from 'antd';
import { MoreOutlined, UsergroupAddOutlined } from '@ant-design/icons';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import axios from 'axios';
import { API_ROOT } from 'modules/request';
import { useAuthContext } from 'components/shared/Auth';
import Link from 'components/shared/elements/Link';
import ZotzonAvatar from 'components/shared/elements/images/ZotzonAvatar';
import classNames from 'classnames';

export const follow = (id, accessToken) => {
  axios // Why axios? We have getRequest
    .put(
      `${API_ROOT}/profile/followers`,
      {
        id,
      },
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      },
    )
    .then((response) => {})
    .catch((error) => {});
};

export const unFollow = (id, accessToken) => {
  axios
    .delete(
      `${API_ROOT}/profile/followers`,
      {
        id,
      },
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      },
    )
    .then((response) => {})
    .catch((error) => {});
};

export default function CardHeader({ item, isVip = false }) {
  const router = useRouter();
  // const onlyInMobile = isVip ? null : css.onlyInMobile;
  const onlyInMobile = null;
  const { accessToken } = useAuthContext();
  const { isAuth, data } = useAuthContext();
  const [isLoggedIn, setLoggedIn] = useState(false);
  useEffect(() => setLoggedIn(isAuth), [isAuth]);
  const { t } = useTranslation('common');

  const address = router.locale === 'ru' ? item.locationNameRu : item.locationNameEn;

  const menuItems = [
    {
      key: '1',
      label: (
        <span
          onClick={() => {
            follow(item.authorId, accessToken);
          }}
        >
          <UsergroupAddOutlined /> {t('follow')}
        </span>
      ),
    },
  ];
  const autorPage = `/users/${item.authorId}`;

  const authorAvatarUrl = item?.authorAvatarUrl || item?.author?.avaterUrl;

  return (
    <div className={classNames(onlyInMobile, styles.headerDiv)}>
      <div className={styles.headerDiv1}>
        <div className={styles.headerDiv2}>
          {authorAvatarUrl && (
            <div>
              <Link className={css.cardAutorlink} href={autorPage}>
                <ZotzonAvatar
                  className={styles.headerZotzonAvatar}
                  version='small'
                  alt={item.authorName}
                  src={authorAvatarUrl}
                />
              </Link>
            </div>
          )}
          {!authorAvatarUrl && (
            <div className={styles.headerDiv3}>
              <Link className={css.cardAutorlink} href={autorPage}>
                <UserOutlined className={styles.headerUserOutlined} />
              </Link>
            </div>
          )}
        </div>
        <div className={styles.headerDiv4}>
          <Link className={css.cardAutorlink} href={autorPage}>
            {item.authorName}
          </Link>
        </div>
        {isLoggedIn && data?.id !== item.authorId && (
          <div className={styles.headerDiv5}>
            <Dropdown menu={{ items: menuItems }} placement='bottomRight'>
              <MoreOutlined className={styles.headerMoreOutlined} />
            </Dropdown>
          </div>
        )}
      </div>
      {address && (
        <div className={styles.headerDiv6}>
          <Tooltip placement='bottomLeft' trigger='click' title={address}>
            <EnvironmentOutlined />
          </Tooltip>
          {/*{address}*/}
        </div>
      )}
    </div>
  );
}
