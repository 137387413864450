import React, { useState } from 'react';
import Link from 'components/shared/elements/Link';
import css from '../../ant/css/cards.module.css';
import styles from '../../ant/css/stylesIndex.module.css';
import { Typography } from 'antd';
import { useTranslation } from 'next-i18next';
import classNames from 'classnames';

const { Paragraph } = Typography;

export default function CardTitle({ item, isVip = false }) {
  const onlyInMobile = isVip ? {} : css.onlyInMobile;
  const [ellipsisT, setEllipsisT] = useState(true);
  const [ellipsis, setEllipsis] = useState(true);
  const { t } = useTranslation('common');

  const tags = String(item.description).match(/(^|\s)(#[a-zа-я\d-]+)/gi);
  const tagsA = String(item.description).match(/(^|\s)(@[a-zа-я\d-]+)/gi);
  let text = String(item.description).replace(/(^|\s)(#[a-zа-я\d-]+)/gi, '');
  text = text.replace(/(^|\s)(@[a-zа-я\d-]+)/gi, '');

  return (
    <>
      <div className={css.cardTitle}>
        <div>
          <Paragraph className={styles.cardTitleParagraph} ellipsis={ellipsisT}>
            <Link className={css.cardTitlelink} style={{ color: '#0c6191' }} href={`/listings/${item.slug}`}>
              {item.title}
            </Link>
          </Paragraph>
        </div>
        {item.price !== 0 && item.price !== '0.00' && (
          <div className={css.price}>
            {item.price} {item.currency}
          </div>
        )}
      </div>

      <div className={classNames(css.cardDesc, onlyInMobile)}>
        <Paragraph
          onClick={() => {
            setEllipsis(false);
            setEllipsisT(false);
          }}
          ellipsis={ellipsis ? { rows: 1, expandable: true, symbol: t('moreMaiPage') } : false}
        >
          {text}

          {tags && (
            <div className={classNames(css.tag, styles.cardTitleTag)}>
              {tags.map((item, i) => {
                return (
                  <Link key={i} className={styles.cardLinkKey} href={`/search/?t=${encodeURIComponent(item)}`}>
                    {item}
                  </Link>
                );
              })}
            </div>
          )}
          {tagsA && (
            <div className={classNames(css.tag, styles.cardTitleTag)}>
              {tagsA.map((item, i) => {
                return (
                  <Link key={i} className={styles.cardLinkKey} href={`/search/?t=${encodeURIComponent(item)}`}>
                    {item}
                  </Link>
                );
              })}
            </div>
          )}
        </Paragraph>
      </div>
    </>
  );
}
